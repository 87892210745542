import { showError } from "@actions";
import { message } from "antd";
import {
  MemberSurveysClient,
  SearchMemberSurveyHistorysWithPaginationQuery,
  SearchMemberSurveysWithPaginationQuery,
  CreateMemberSurveyCommand,
  UpdateMemberSurveyCommand,
  AssessmentType,
  AssessmentType2,
} from "@api";

const client = new MemberSurveysClient(
  process.env.REACT_APP_EXTERNAL_API_BASEURL
);

export async function Search(
  searchParams: SearchMemberSurveyHistorysWithPaginationQuery
) {
  try {
    const result = await client.search(searchParams);
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}

export async function SearchMemberSurveys(
  searchParams: SearchMemberSurveysWithPaginationQuery
) {
  try {
    const result = await client.searchMemberSurveys(searchParams);
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}

export async function GetList(memberInfoId: string, formType: AssessmentType2) {
  try {
    const result = await client.getList(memberInfoId, formType);

    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}

export async function Get(id: number) {
  try {
    const result = await client.get(id);
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}

export async function Create(entity: CreateMemberSurveyCommand) {
  try {
    const result = await client.create(entity);
    message.success("Created Successfully.");
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}

export async function Update(id: number, entity: UpdateMemberSurveyCommand) {
  try {
    const result = await client.update(id, entity);
    message.success("Updated Successfully.");
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}

export async function Delete(id: number) {
  try {
    const result = await client.delete(id);
    message.success("Deleted Successfully.");
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}

// function convertToAssessmentType2(formType: AssessmentType): AssessmentType2 {
//   switch (formType) {
//     case AssessmentType.ClinicalOrCaseAssessment:
//       return AssessmentType2.ClinicalOrCaseAssessment;
//     case AssessmentType.RecoveryAssessment:
//       return AssessmentType2.RecoveryAssessment;
//     case AssessmentType.VolunteerAssessment:
//       return AssessmentType2.VolunteerAssessment;
//     case AssessmentType.SBUAssessmentResults:
//       return AssessmentType2.SBUAssessmentResults;
//     case AssessmentType.VolunteerEvaluationRecord:
//       return AssessmentType2.VolunteerEvaluationRecord;
//     case AssessmentType.VolunteerFeedbackForm:
//       return AssessmentType2.VolunteerFeedbackForm;
//     case AssessmentType.OtherSurveyOrQuestionnaire:
//       return AssessmentType2.OtherSurveyOrQuestionnaire;
//     default:
//       throw new Error(`Unknown AssessmentType value: ${formType}`);
//   }
// }
