import React, { useState } from "react";
import { Table as AntTable, Grid, TableProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Resizable } from "react-resizable";
import type { ResizeCallbackData } from "react-resizable";

interface ITable extends TableProps<any> {
  pageMeta?: IPageMeta;
  onPageChange?(value: any): void;
  showPagination?: boolean;
  defaultOrder?: IQueryOrder;
  defaultExpandAllRows?: boolean;
  expandColumn?: string;
  isResizable?: boolean;
}

// 自定义拖拽标题
const ResizableTitle = (
  props: React.HTMLAttributes<any> & {
    onResize: (
      e: React.SyntheticEvent<Element>,
      data: ResizeCallbackData
    ) => void;
    width: number;
  }
) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const Table = ({
  pageMeta,
  onPageChange = () => {},
  showPagination = true,
  defaultOrder = {
    orderBy: "Id",
    isOrderByAscending: false,
  },
  defaultExpandAllRows = false,
  expandColumn = undefined,
  isResizable = true,
  ...rest
}: ITable) => {
  const { xl } = Grid.useBreakpoint();
  const handleChange = (
    { current, pageSize }: any,
    _: any,
    sorter: any,
    { action }: any
  ) => {
    switch (action) {
      case "paginate":
        onPageChange({ pageNumber: current, pageSize: pageSize });
        break;
      case "sort":
        if (sorter.order) {
          let orderBy;
          if (!!sorter.columnKey) {
            orderBy =
              sorter.columnKey.charAt(0).toUpperCase() +
              sorter.columnKey.slice(1);
          } else {
            orderBy =
              sorter.field.charAt(0).toUpperCase() + sorter.field.slice(1);
          }
          onPageChange({
            pageNumber: 1,
            orderBy: orderBy,
            isOrderByAscending: sorter.order === "ascend",
          });
        } else {
          onPageChange({
            pageNumber: 1,
            orderBy: defaultOrder.orderBy,
            isOrderByAscending: defaultOrder.isOrderByAscending,
          });
        }

        break;
      default:
        break;
    }
  };

  const expandable: any = expandColumn
    ? {
        defaultExpandAllRows: defaultExpandAllRows,
        rowExpandable: (record: any) => !!record[expandColumn],
        expandedRowRender: (record: any) => (
          <p style={{ margin: 0 }}>{record[expandColumn]}</p>
        ),
      }
    : null;

  // 表格列
  const [columns, setColumns] = useState<ColumnsType<any>>([
    ...rest.columns!.map((col, index) => ({
      ...col,
      width: 100,
    }))!,
  ]);

  const handleResize: Function =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      setColumns(newColumns);
    };

  // 处理表格列
  const mergeColumns: ColumnsType<any> = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnsType<any>[number]) => ({
      width: column.width,
      onResize: handleResize(index) as React.ReactEventHandler<any>,
    }),
  }));

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  return (
    <AntTable
      size={xl ? "middle" : "small"}
      onChange={handleChange}
      rowKey="id"
      pagination={
        showPagination
          ? {
              pageSizeOptions: [5, 10, 20, 50, 100],
              size: "default",
              showSizeChanger: true,
              ...pageMeta,
            }
          : false
      }
      expandable={expandable}
      {...rest}
      components={isResizable ? components : undefined}
      columns={isResizable ? mergeColumns : rest.columns}
    />
  );
};

export default Table;
