import { useReducer, useEffect } from "react";
import { Card, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { SearchButton, AdvanceSearchCard, Button, YesOrNoIcon } from "@library";
import { SurveyDto, SearchSurveysWithPaginationQuery } from "@api";
import { ITableColumn, ISearchOption, InputFieldType } from "@types";
import { showError } from "@actions";
import { Search } from "@components/Surveys/SurveyAPI";
import { getAssessmentTypeTitle, formatDateTime } from "@actions";
import { fetchAssessmentTypes } from "@common-api";
import { useNavigate } from "react-router-dom";

enum ActionKind {
  isLoading,
  dataSource,
  fetchData,
  pageChange,
  params,
  initPage,
  init,
  assessmentTypes,
}

interface IState {
  isLoading: boolean;
  params: SearchSurveysWithPaginationQuery;
  dataSource: SurveyDto[] | undefined;
  pageMeta: IPageMeta;
  assessmentTypes: IOption[];
}

type IReducerAction = {
  type: ActionKind;
  payload: any;
};

const IndexPage = () => {
  // const navigate = useNavigate();

  // const initialState: IState = {
  //   isLoading: false,
  //   params: {
  //     orderBy: "created",
  //     isOrderByAscending: false,
  //     pageNumber: 1,
  //     pageSize: 10,
  //   },
  //   dataSource: [],
  //   pageMeta: { current: 1, total: 0, pageSize: 0 },

  //   assessmentTypes: [],
  // };

  // const reducer = (
  //   state: IState,
  //   { type, payload }: IReducerAction
  // ): IState => {
  //   switch (type) {
  //     case ActionKind.isLoading:
  //       return {
  //         ...state,
  //         isLoading: payload,
  //       };
  //     case ActionKind.fetchData:
  //       return {
  //         ...state,
  //         isLoading: false,
  //         dataSource: payload?.items,
  //         pageMeta: {
  //           current: payload?.pageNumber,
  //           total: payload?.totalCount,
  //           pageSize: state.params.pageSize,
  //         },
  //       };
  //     case ActionKind.pageChange:
  //       return {
  //         ...state,
  //         params: {
  //           ...state.params,
  //           ...payload,
  //         },
  //       };
  //     case ActionKind.params:
  //       return {
  //         ...state,
  //         params: {
  //           ...initialState.params,
  //           ...payload,
  //         },
  //       };
  //     case ActionKind.initPage:
  //       return {
  //         ...state,
  //       };

  //     case ActionKind.assessmentTypes:
  //       return {
  //         ...state,
  //         assessmentTypes: payload,
  //       };
  //     case ActionKind.init:
  //       return {
  //         ...initialState,
  //       };
  //     default:
  //       return state;
  //   }
  // };

  // const [
  //   { dataSource, isLoading, pageMeta, params, assessmentTypes },
  //   dispatch,
  // ] = useReducer(reducer, initialState);

  // const columns: ITableColumn<SurveyDto> = [
  //   {
  //     title: "Form Name",
  //     dataIndex: "formName",
  //     key: "formName",
  //     sorter: true,
  //   },
  //   {
  //     title: "Form Type",
  //     dataIndex: "formTypeName",
  //     key: "formTypeName",
  //     sorter: true,
  //     render: (value) => <>{getAssessmentTypeTitle(value)}</>,
  //   },
  //   {
  //     title: "Need Password",
  //     dataIndex: "password",
  //     render: (value) => <YesOrNoIcon value={value} />,
  //   },
  //   {
  //     title: "Creation Date",
  //     dataIndex: "created",
  //     key: "created",
  //     sorter: true,
  //     render: (value) => (value ? formatDateTime(value) : "-"),
  //   },
  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (_, record) => (
  //       <Space>
  //         <SearchButton href={`/detail/${record.id}`}></SearchButton>
  //         <Button
  //           type="primary"
  //           icon={<EditOutlined />}
  //           onClick={() => {
  //             navigate(`/create/${record.id}`);
  //           }}
  //         >
  //           Fill in
  //         </Button>
  //       </Space>
  //     ),
  //   },
  // ];

  // const searchOptions: ISearchOption[] = [
  //   {
  //     label: "FormName",
  //     value: "formName",
  //   },
  //   {
  //     label: "FormType",
  //     value: "formType",
  //     fields: [
  //       {
  //         name: "formType",
  //         type: InputFieldType.Select,
  //         options: assessmentTypes,
  //       },
  //     ],
  //   },
  //   {
  //     label: "Creation Date",
  //     value: "created",
  //     fields: [
  //       {
  //         label: "From",
  //         name: "dateFrom",
  //         type: InputFieldType.Date,
  //       },
  //       {
  //         label: "To",
  //         name: "dateTo",
  //         type: InputFieldType.Date,
  //       },
  //     ],
  //   },
  // ];

  // const fetchData = async (searchParams: SearchSurveysWithPaginationQuery) => {
  //   try {
  //     dispatch({ type: ActionKind.isLoading, payload: true });
  //     const result = await Search({ ...searchParams, isEnabled: true });
  //     dispatch({ type: ActionKind.fetchData, payload: result });
  //   } catch (error) {
  //     showError(error);
  //     dispatch({ type: ActionKind.isLoading, payload: false });
  //   }
  // };

  // useEffect(() => {
  //   const init = async () => {
  //     // AssessmentTypes
  //     const assessmentTypes = await fetchAssessmentTypes();
  //     dispatch({ type: ActionKind.assessmentTypes, payload: assessmentTypes });
  //   };
  //   init();
  // }, []);

  // useEffect(() => {
  //   fetchData(params);
  // }, [params]);

  return (
    <Card>
      <h1>Hello!</h1>
      <h1>Welcome to form platform!</h1>
    </Card>
  );
};
export default IndexPage;
