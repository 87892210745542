export const genders = [
  {
    value: "M",
    label: "男",
  },
  {
    value: "F",
    label: "女",
  },
  {
    value: "NA",
    label: "中性",
  },
];
export const lunarMapping = {
  month: [
    "正月",
    "二月",
    "三月",
    "四月",
    "五月",
    "六月",
    "七月",
    "八月",
    "九月",
    "十月",
    "冬月",
    "臘月",
    "閏正月",
    "閏二月",
    "閏三月",
    "閏四月",
    "閏五月",
    "閏六月",
    "閏七月",
    "閏八月",
    "閏九月",
    "閏十月",
    "閏冬月",
    "閏臘月",
  ],
  day: [
    "初一",
    "初二",
    "初三",
    "初四",
    "初五",
    "初六",
    "初七",
    "初八",
    "初九",
    "初十",
    "十一",
    "十二",
    "十三",
    "十四",
    "十五",
    "十六",
    "十七",
    "十八",
    "十九",
    "二十",
    "廿一",
    "廿二",
    "廿三",
    "廿四",
    "廿五",
    "廿六",
    "廿七",
    "廿八",
    "廿九",
    "三十",
  ],
};

export const uploadProps = {
  uploadListType: "picture",
  maxCount: 1,
  beforeUpload: () => false,
  accept:
    "image/*, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ,	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};
export const disabledHours = () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 23];

export const uploadHelper =
  "Files cannot be larger than 10 Mbs, and only accept pictures, PDF and general Office file formats";

export const uploadExcelHelper =
  "The file cannot be larger than 10 Mbs, and only Excel file format is accepted";

export const showSelectProps = {
  dropdownMatchSelectWidth: false,
  showSearch: true,
  filterOption: (input: string, option: any) =>
    "label" in option
      ? option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
};

export const DefaultLocale = {
  everyText: "每",
  emptyMonths: "每個月",
  emptyMonthDays: "每一日",
  emptyMonthDaysShort: "每日",
  emptyWeekDays: "每日",
  emptyWeekDaysShort: "平日",
  emptyHours: "每小時",
  emptyMinutes: "每分鐘",
  emptyMinutesForHourPeriod: "每",
  yearOption: "年",
  monthOption: "月",
  weekOption: "週",
  dayOption: "日",
  hourOption: "小時",
  minuteOption: "分鐘",
  rebootOption: "reboot",
  prefixPeriod: "每",
  prefixMonths: "的",
  prefixMonthDays: "的",
  prefixWeekDays: "的",
  prefixWeekDaysForMonthAndYearPeriod: "及",
  prefixHours: "的",
  prefixMinutes: ":",
  prefixMinutesForHourPeriod: "的",
  suffixMinutesForHourPeriod: "分鐘",
  errorInvalidCron: "Invalid cron expression",
  clearButtonText: "清除",
  weekDays: [
    // Order is important, the index will be used as value
    "星期日", // Sunday must always be first, it's "0"
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ],
  months: [
    // Order is important, the index will be used as value
    "一月",
    "二月",
    "三月",
    "四月",
    "五月",
    "六月",
    "七月",
    "八月",
    "九月",
    "十月",
    "十一月",
    "十二月",
  ],
};

export const weekDays = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const textAreaMaxLength = 10000;
export const inputMaxLength = 1000;
