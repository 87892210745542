import { MemberInfoDto } from "@api";
import { createContext } from "react";

interface IStoreContext {
  currentMember: MemberInfoDto;
  breadcrumb?: string;
  logout(): void;
  setIsLoading(value: boolean): void;
  setBreadcrumb(value?: string): void;
}

export default createContext<IStoreContext>({
  currentMember: {},
  logout: () => {},
  setBreadcrumb: (value?: string) => {},
  setIsLoading: (value: boolean) => {},
});
