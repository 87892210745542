import {
  InputProps,
  RadioGroupProps,
  SelectProps,
  DatePickerProps,
  TimePickerProps,
  TimeRangePickerProps,
  SwitchProps,
  AutoCompleteProps,
  InputNumberProps,
  FormItemProps,
  RateProps,
} from "antd";
import { CheckboxGroupProps } from "antd/lib/checkbox";
import type { ColumnsType } from "antd/es/table";

export enum InputType {
  //InputProps
  Input,
  Password,
  AutoInput,
  TextArea,

  //RadioGroupProps
  BoolRadio,
  Radio,
  RadioWithOther,

  //DatePickerProps
  DatePicker,
  DateTimePicker,
  RangePicker,

  //TimePickerProps
  TimePicker,

  //TimeRangePickerProps
  TimeRangePicker,

  //SelectProps
  Select,
  BoolSelect,
  SelectWithOther,

  //CheckboxProps
  CheckBox,
  CheckBoxWithOther,
  SingleCheckBox,

  //SwitchProps
  Switch,

  //AutoCompleteProps
  AutoComplete,

  //InputNumberProps
  InputNumber,

  //any
  TimeSlot,
  Rate,
}

type FieldsTypeBase = {
  label?: string;
  name: string;
  inputType: InputType;
  span?: 1 | 2 | 3;
  mainName?: string;
  formItemProps?: FormItemProps;
  dependencies?: DependenciesType;
  link?: string;
  linkParam?: string;
  isEmpty?: boolean;
  options?: IOption[];
  otherName?: string;
  otherLabel?: string;
  valueRender?(value?: any, data?: any, options?: IOption[]): JSX.Element;
  fieldRender?(
    label?: string,
    name?: string,
    rules?: any,
    options?: IOption[],
    value?: any
  ): JSX.Element;
};

export type FieldsType = (
  | {
      inputType: InputType.Input | InputType.Password | InputType.TextArea;
      inputProps?: InputProps;
    }
  | {
      inputType:
        | InputType.BoolRadio
        | InputType.Radio
        | InputType.RadioWithOther;
      inputProps?: RadioGroupProps;
      options: IOption[];
    }
  | {
      inputType:
        | InputType.DatePicker
        | InputType.DateTimePicker
        | InputType.RangePicker;
      inputProps?: DatePickerProps;
    }
  | {
      inputType: InputType.TimePicker;
      inputProps?: TimePickerProps;
    }
  | {
      inputType: InputType.TimeRangePicker;
      inputProps?: TimeRangePickerProps;
    }
  | {
      inputType:
        | InputType.Select
        | InputType.BoolSelect
        | InputType.SelectWithOther;
      inputProps?: SelectProps;
      options: IOption[];
    }
  | {
      inputType:
        | InputType.CheckBox
        | InputType.CheckBoxWithOther
        | InputType.SingleCheckBox;
      inputProps?: CheckboxGroupProps;
      options: IOption[];
    }
  | {
      inputType: InputType.Switch;
      inputProps?: SwitchProps;
    }
  | {
      inputType: InputType.AutoComplete | InputType.AutoInput;
      inputProps?: AutoCompleteProps;
    }
  | {
      inputType: InputType.InputNumber;
      inputProps?: InputNumberProps;
    }
  | {
      inputType: InputType.TimeSlot;
      inputProps?: any;
    }
  | {
      inputType: InputType.BoolRadio | InputType.BoolSelect;
      inputProps?: SelectProps | RadioGroupProps;
      options?: IOption[];
    }
  | {
      inputType:
        | InputType.RadioWithOther
        | InputType.SelectWithOther
        | InputType.CheckBoxWithOther
        | InputType.AutoComplete
        | InputType.AutoInput;
      inputProps?:
        | SelectProps
        | RadioGroupProps
        | CheckboxGroupProps
        | AutoCompleteProps;
      otherName: string;
      options: IOption[];
    }
  | {
      inputType: InputType.Rate;
      inputProps?: RateProps;
    }
) &
  FieldsTypeBase;

export interface ITableColumn<T> extends ColumnsType<T> {}

export enum InputFieldType {
  Input,
  MultiSelect,
  Date,
  Number,
  Select,
  SelectWithSearch,
  CheckBox,
  AutoComplete,
  User,
}

export interface ISearchOptionField {
  name: string;
  label?: string;
  type?: InputFieldType;
  options?: IOption[];
}

export interface ISearchOption {
  value: string;
  label: string;
  span?: 1 | 2 | 3 | 4;
  isAdvance?: boolean;
  fields?: ISearchOptionField[];
}
