import { useState } from "react";
import { message } from "antd";

import "survey-core/defaultV2.min.css";

import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";

StylesManager.applyTheme("defaultV2");

interface ISurveyContent {
  json: string;
  data?: any;
  showCompletedPage?: boolean;
  mode?: "edit" | "display";
  onComplete(value: any): any;
}
StylesManager.applyTheme("modern");

const SurveyContent = ({
  json,
  data,
  mode = "edit",
  showCompletedPage = false,
  onComplete,
}: ISurveyContent) => {
  const survey = new Model(json);
  const [newMode, setNewMode] = useState(mode);
  survey.data = data;
  if (typeof data === "string") {
    survey.data = JSON.parse(data);
  }

  const defaultOnComplete = () => {
    // setNewMode("display");
  };

  if (showCompletedPage) {
    survey.doComplete();
  }

  survey.onComplete.add(defaultOnComplete);
  survey.onComplete.add(onComplete);

  return <Survey model={survey} mode={newMode} />;
};

export default SurveyContent;
