import { RedoOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
interface IResetButton extends ButtonProps {
  children?: any;
}
const ResetButton = ({ children = "Reset", ...rest }: IResetButton) => (
  <Button icon={<RedoOutlined />} danger {...rest}>
    {children}
  </Button>
);

export default ResetButton;
