import React from "react";
import { Space } from "antd";

interface IFlexEndSpace {
  children: any;
  justifyContent?: any;
  marginBottom?: any;
  width?: string;
}
const FlexEndSpace = ({
  justifyContent = "flex-end",
  children,
  marginBottom = "10px",
  width = "100%",
  ...rest
}: IFlexEndSpace) => (
  <Space
    style={{
      justifyContent: justifyContent,
      width: width,
      marginBottom: marginBottom,
    }}
    {...rest}
  >
    {children}
  </Space>
);

export default FlexEndSpace;
