import React from "react";
import { Layout } from "./layouts";
import { useAuth } from "react-oidc-context";
import { StoreProvider } from "@providers";
import { AppRoutes } from "@routes";
import { LoadingScreen } from "@library";

function App(): JSX.Element {
  const auth = useAuth();
  if (
    auth.isLoading ||
    auth.activeNavigator ||
    auth.error?.message === "No matching state found in storage"
  ) {
    return <LoadingScreen />;
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect({
      state: { returnUrl: encodeURIComponent(window.location.pathname) },
    });
  }
  return (
    <StoreProvider>
      <Layout>
        <AppRoutes />
      </Layout>
    </StoreProvider>
  );
}
export default App;
