import React from "react";
import { Modal as CustomModal, Grid, ModalProps } from "antd";

interface IModal extends ModalProps {
  isLoading?: boolean;
  isNarrow?: boolean;
  children: JSX.Element | JSX.Element[];
}

const Modal = ({
  isLoading,
  isNarrow = false,
  children,
  visible,
  ...rest
}: IModal) => {
  const { xl } = Grid.useBreakpoint();
  const width = isNarrow ? 550 : 1100;
  return (
    <CustomModal
      width={xl ? width : "100%"}
      centered
      destroyOnClose={true}
      maskClosable={false}
      visible={visible}
      confirmLoading={isLoading}
      {...rest}
    >
      {children}
    </CustomModal>
  );
};

export default Modal;
