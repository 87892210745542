import React from "react";
import InlineAdvanceSearchForm from "./InlineAdvanceSearchForm";
import { Card } from "antd";
import { ISearchOption } from "@types";

interface IAdvanceSearchForm {
  isLoading: boolean;
  filterSetting?: IFilterSetting;
  searchOptions?: ISearchOption[];
  onSearch(value: any): void;
}

const AdvanceSearchForm = ({
  searchOptions = [],
  filterSetting,
  onSearch,
  isLoading,
}: IAdvanceSearchForm) => {
  const onExpressionSearch = (values: any) => {
    onSearch({ expression: values });
  };
  return filterSetting ? (
    // <QueryBuilder
    //   filterSetting={filterSetting}
    //   isLoading={isLoading}
    //   onExpressionSearch={onExpressionSearch}
    // />
    <></>
  ) : (
    <Card className="query-card" title={"Search"}>
      <InlineAdvanceSearchForm
        searchOptions={searchOptions}
        onSearch={onSearch}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default AdvanceSearchForm;
