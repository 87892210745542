import React, { useState, useEffect } from "react";
import { Card, TableProps } from "antd";
import Table from "../Table";
import SearchForm from "./SearchForm";
import AdvanceSearchForm from "./AdvanceSearchForm";
import InlineAdvanceSearchForm from "./InlineAdvanceSearchForm";
import { ISearchOption } from "@types";

interface IAdvanceSearchCard extends TableProps<any> {
  pageMeta: IPageMeta;
  columns: any;
  dataSource: any;
  isLoading: boolean;
  filterSetting?: IFilterSetting;
  searchOptions?: ISearchOption[];
  cardTitle?: string;
  hiddenAdvance?: boolean;
  hiddenSearch?: boolean;
  defaultOrder?: IQueryOrder;
  extra?: JSX.Element[] | JSX.Element;
  onSearch(value: any): void;
  onPageChange(value: any): void;
  isAdvancedSearch?: boolean;
  isResizable?: boolean;
}

const AdvanceSearchCard = ({
  cardTitle = "",
  hiddenAdvance = false,
  hiddenSearch = true,
  defaultOrder = {
    orderBy: "Id",
    isOrderByAscending: false,
  },
  filterSetting,
  columns,
  searchOptions = [],
  extra = [],
  dataSource,
  isLoading,
  pageMeta,
  onPageChange,
  onSearch,
  isAdvancedSearch = true,
  isResizable = true,
  ...rest
}: IAdvanceSearchCard) => {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const commonSearchOptions = searchOptions.filter((x) => !x.isAdvance);

  return (
    <div>
      {!hiddenAdvance && (
        <AdvanceSearchForm
          isLoading={isLoading}
          filterSetting={filterSetting}
          searchOptions={isAdvanced ? searchOptions : commonSearchOptions}
          onSearch={onSearch}
        />
      )}

      <Card
        title={cardTitle}
        bodyStyle={{ paddingTop: "0px" }}
        extra={
          // <Row gutter={[24, 24]} align="middle">
          //   <Col key="Advanced Search">
          //     <Switch
          //       checked={isAdvanced}
          //       onChange={(values) => {
          //         setIsAdvanced(values);
          //       }}
          //     />
          //   </Col>
          //   {extra?.map((ExtraItem, index) => (
          //     <Col key={index}>{ExtraItem}</Col>
          //   ))}
          // </Row>
          <SearchForm
            extra={extra}
            hiddenSearch={hiddenSearch}
            hiddenAdvance={hiddenAdvance}
            setIsAdvanced={setIsAdvanced}
            isAdvanced={isAdvanced}
            onFinish={onSearch}
            isLoading={isLoading}
            searchOptions={isAdvanced ? searchOptions : commonSearchOptions}
            isAdvancedSearch={isAdvancedSearch}
          />
        }
      >
        <Table
          {...rest}
          defaultOrder={defaultOrder}
          loading={isLoading}
          dataSource={dataSource}
          columns={columns}
          pageMeta={pageMeta}
          onPageChange={onPageChange}
          isResizable={isResizable}
        />
      </Card>
    </div>
  );
};

AdvanceSearchCard.InlineAdvanceSearchForm = InlineAdvanceSearchForm;
export default AdvanceSearchCard;
