import React from "react";
import { Form, Input } from "antd";
import { inputMaxLength } from "@objects";
import InputField from "./InputField";
import { ISearchOptionField } from "@types";

interface ISelectedFormItem {
  fields: ISearchOptionField[];
  isAdvanced: boolean;
}
const SelectedFormItem = ({ fields = [], isAdvanced }: ISelectedFormItem) => {
  return (
    <>
      {fields?.length ? (
        fields.map((props, index) => <InputField {...props} key={index} />)
      ) : (
        <Form.Item name="inputValue">
          <Input
            style={{ padding: "1px 11px" }}
            disabled={isAdvanced}
            maxLength={inputMaxLength}
          />
        </Form.Item>
      )}
    </>
  );
};

export default SelectedFormItem;
