import TransFormData from "./TransFormData";
import { Descriptions, Grid, Col, Row, Form, DescriptionsProps } from "antd";
import { FieldsType } from "@types";
import CustomFormItem from "./CustomFormItem";
import CustomDescriptionsItem from "./CustomDescriptionsItem";

interface ICustomFormDescriptions {
  title?: string;
  fields: FieldsType[];
  data?: any;
  isEditing?: boolean;
  formSpan?: number;
  customDescriptionProps?: DescriptionsProps;
  getFieldValue(name: string): any;
}
const CustomFormDescriptions = ({
  title,
  fields,
  data,
  customDescriptionProps,
  isEditing = false,
  formSpan,
  getFieldValue,
}: ICustomFormDescriptions) => {
  const { xl } = Grid.useBreakpoint();
  const isRender = fields && Array.isArray(fields);

  return isEditing ? (
    <Row gutter={[12, 12]} className="custom-form-descriptions">
      <Col span={24} style={{ fontWeight: " bold", fontSize: "20px" }}>
        {title}
      </Col>
      {isRender &&
        fields.map((field, index) => {
          if (!field) return null;
          const {
            span = 1,
            mainName,
            name,
            dependencies = { name: null, value: null, isNotEqual: false },
            ...rest
          } = field;
          const colSpan = formSpan ?? span * 8;
          const formName = mainName ? [mainName, name] : name;

          return dependencies.name ? (
            <Form.Item
              noStyle
              shouldUpdate={(preValues, currValues) =>
                preValues[dependencies.name!] !== currValues[dependencies.name!]
              }
              key={name + "." + index}
            >
              {() =>
                ((!dependencies.isNotEqual &&
                  getFieldValue(dependencies.name!) === dependencies.value) ||
                  (dependencies.isNotEqual &&
                    getFieldValue(dependencies.name!) !==
                      dependencies.value)) && (
                  <Col span={colSpan}>
                    <CustomFormItem
                      name={formName}
                      getFieldValue={getFieldValue}
                      {...rest}
                    />
                  </Col>
                )
              }
            </Form.Item>
          ) : (
            <Col span={colSpan} key={name + "." + index}>
              <CustomFormItem
                name={formName}
                getFieldValue={getFieldValue}
                {...rest}
              />
            </Col>
          );
        })}
    </Row>
  ) : (
    <Descriptions
      layout={xl ? "horizontal" : "vertical"}
      {...customDescriptionProps}
      title={title}
    >
      {isRender
        ? fields.map((field, index) => {
            if (!field) return null;
            const {
              dependencies = { name: null, value: null, isNotEqual: false },
              span = 1,
              label,
              name,
              mainName,
              inputProps,
              inputType,
              options,
              link,
              linkParam,
              isEmpty,
              otherName,
              valueRender,
            } = field;
            let newLink;
            if (link) newLink = linkParam ? link + data[linkParam] : link;

            if (dependencies.name) {
              const canRender =
                (data[dependencies.name] === dependencies.value) ===
                !dependencies.isNotEqual;
              if (!canRender) return null;
            }

            let itemValue = TransFormData({
              inputType,
              data,
              name,
              mainName,
              options,
              otherName,
            });

            return (
              <Descriptions.Item
                span={span}
                label={label}
                key={index}
                style={{ whiteSpace: "normal" }}
              >
                <CustomDescriptionsItem
                  data={data}
                  inputType={inputType}
                  value={itemValue}
                  options={options}
                  valueRender={valueRender}
                  link={newLink}
                  isEmpty={isEmpty}
                  inputProps={inputProps}
                />
              </Descriptions.Item>
            );
          })
        : null}
    </Descriptions>
  );
};
export default CustomFormDescriptions;
