import React from "react";
import { Layout as AntLayout } from "antd";
import Footer from "./Footer";
import { Header } from "./header";
import Breadcrumb from "./Breadcrumb";

const { Content } = AntLayout;
interface ILayout {
  children: JSX.Element;
}

const Layout = ({ children }: ILayout): JSX.Element => {
  return (
    <AntLayout className="AntLayout">
      <Header />
      <Content>
        {/* <Breadcrumb /> */}
        {children}
      </Content>
      <Footer />
    </AntLayout>
  );
};
export default Layout;
