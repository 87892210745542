import React from "react";
import { Spin } from "antd";

const LoadingScreen = () => (
  <div
    style={{
      background: "rgba(0, 0, 0, 0.2)",
      position: "fixed",
      width: "100%",
      minHeight: "100%",
      zIndex: 9999,
    }}
  >
    <Spin style={{ position: "fixed", top: "50%", left: "50%" }} size="large" />
  </div>
);

export default LoadingScreen;
