import { showError } from "@actions";
import { message } from "antd";
import { SurveysClient, SearchSurveysWithPaginationQuery } from "@api";

const client = new SurveysClient(process.env.REACT_APP_EXTERNAL_API_BASEURL);

export async function Get(id: string) {
  try {
    const result = await client.get(id);
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}

export async function Search(searchParams: SearchSurveysWithPaginationQuery) {
  try {
    const result = await client.search(searchParams);
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}
