import { showError, downloadFile } from "@actions";
import { message } from "antd";
import { MembersClient } from "@api";

const client = new MembersClient(process.env.REACT_APP_EXTERNAL_API_BASEURL);

export async function Get(id: string) {
  try {
    const result = await client.get(id);
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}
export async function GetCurrent() {
  try {
    const result = await client.getCurrent();
    return result;
  } catch (error) {
    showError(error);
    throw error;
  }
}
