import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthenticationProvider } from "@providers";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "antd/dist/antd.css";
import "antd/dist/reset.css";
import "./themes/global.css";
import { Helmet } from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <title>BOKSS - Form Platform</title>
    </Helmet>
    <BrowserRouter>
      <AuthenticationProvider>
        <App />
      </AuthenticationProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
