import { InputType } from "@types";

interface ITransFormData {
  inputType: InputType;
  name: string;
  data: any;
  options?: IOption[];
  mainName?: string;
  otherName?: string;
}

const TransFormData = ({
  inputType,
  data,
  name,
  mainName,
  options = [],
  otherName,
}: ITransFormData) => {
  if (!data) return null;

  switch (inputType) {
    case InputType.CheckBoxWithOther:
      if (!data[name]) return [];
      const optionArr = options.filter(
        (item) =>
          data[name].findIndex((key: number) => key === item.value) !== -1
      );

      const resultArr = optionArr.map((item) => {
        let result = item.label;
        if (item.hasRemarks) {
          result = `${result}-${data[item.value + "-Remarks"]}`;
        }
        // if (item.category) result = `${result}*`;
        return result;
      });

      return resultArr;

    case InputType.RadioWithOther:
    case InputType.SelectWithOther: {
      if (!data[name]) return "-";
      let value =
        Array.isArray(data[name]) && data[name].length === 1
          ? data[name][0]
          : data[name];

      const index = options?.findIndex((key: IOption) => key.value === value);

      if (index === -1) {
        return "-";
      }
      if (options[index].hasRemarks) {
        return `${options[index].label} - ${
          data[otherName ? otherName : `${value}-Remarks`]
        }`;
      }
      return options[index].label;
    }

    default:
      return mainName
        ? data[mainName]
          ? data[mainName][name]
          : null
        : data[name];
  }
};
export default TransFormData;
