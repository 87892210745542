import React, { useContext } from "react";
import {
  Input,
  Select,
  DatePicker,
  InputNumber,
  Form,
  AutoComplete,
  Checkbox,
  Space,
} from "antd";
import { showSelectProps, inputMaxLength } from "@objects";
// import { UserDropdown } from "@components/FetchDataDropdown";
// import { Store } from "@store";
import { InputFieldType, ISearchOptionField } from "@types";

interface IInputField extends ISearchOptionField {
  isAdvanced?: boolean;
  isAdvanceForm?: boolean;
}
const InputField = ({
  type,
  name,
  options,
  label,
  isAdvanced = false,
  isAdvanceForm = false,
}: IInputField) => {
  const customLabel = label ?? null;
  switch (type) {
    case InputFieldType.MultiSelect:
      return (
        <Form.Item name={name} label={customLabel}>
          <Select
            {...showSelectProps}
            autoClearSearchValue={false}
            disabled={isAdvanced}
            mode="multiple"
            placeholder="Please select"
            options={options}
            allowClear
          />
        </Form.Item>
      );

    case InputFieldType.Date:
      return (
        <Form.Item name={name} label={customLabel}>
          <DatePicker
            disabled={isAdvanced}
            allowClear
            format={process.env.REACT_APP_DATE_FORMAT}
          />
        </Form.Item>
      );
    case InputFieldType.Number:
      return (
        <Form.Item name={name} label={customLabel}>
          <InputNumber
            disabled={isAdvanced}
            placeholder="Please input"
            type="number"
            precision={0}
          />
        </Form.Item>
      );
    case InputFieldType.Select:
      return (
        <Form.Item
          name={name}
          initialValue={!isAdvanceForm ? options![0]?.value : null}
          label={customLabel}
        >
          <Select
            disabled={isAdvanced}
            placeholder="Please select"
            options={options}
            allowClear
          />
        </Form.Item>
      );
    case InputFieldType.SelectWithSearch:
      return (
        <Form.Item
          name={name}
          initialValue={options![0]?.value}
          label={customLabel}
        >
          <Select
            showSearch
            disabled={isAdvanced}
            placeholder="Please select"
            options={options}
            allowClear
          />
        </Form.Item>
      );
    case InputFieldType.CheckBox:
      return (
        <Form.Item name={name} label={customLabel}>
          <Checkbox.Group>
            <Space wrap>
              {options?.map((o) => (
                <Checkbox value={o.value} key={o.value}>
                  {o.label}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      );
    case InputFieldType.AutoComplete:
      return (
        <Form.Item name={name}>
          <AutoComplete
            disabled={isAdvanced}
            placeholder="Please select"
            options={options}
            filterOption={(inputValue, option) =>
              option!.label.indexOf(inputValue) !== -1
            }
          />
        </Form.Item>
      );

    // case InputFieldType.User:
    //   return (
    //     <UserDropdown
    //       disabled={isAdvanced}
    //       centerId={center?.id}
    //       FormItemProps={{
    //         name,
    //         label: customLabel,
    //       }}
    //     />
    //   );

    default:
      return (
        <Form.Item name={name} label={customLabel}>
          <Input
            maxLength={inputMaxLength}
            placeholder="Please input"
            style={{ padding: "1px 11px" }}
          />
        </Form.Item>
      );
  }
};

export default InputField;
