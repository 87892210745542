import React from "react";
import {
  Space,
  Input,
  Select,
  Radio,
  Checkbox,
  DatePicker,
  InputNumber,
  Switch,
  TimePicker,
  Form,
  Row,
  Col,
  AutoComplete,
  FormItemProps,
  Rate,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { getOtherArr, getOtherInput } from "@actions";
import { InputType } from "@types";

interface ICustomFormItem {
  label?: string;
  name: any;
  inputType: InputType;
  options?: IOption[];
  inputProps?: any;
  formItemProps?: FormItemProps;
  otherName?: string;
  otherLabel?: string;
  otherOptions?: IOption[];
  isEmpty?: boolean;
  getFieldValue(name: string): any;
  fieldRender?(
    label?: string,
    name?: string,
    rules?: any,
    options?: IOption[],
    value?: any
  ): JSX.Element;
}

const CustomFormItem = ({
  label,
  name,
  inputType,
  options = [],
  fieldRender,
  inputProps,
  formItemProps,
  getFieldValue = () => {},
  otherName,
  otherLabel,
  otherOptions,
  isEmpty,
}: ICustomFormItem) => {
  const textAreaMaxLength = 10000;
  const inputMaxLength = 1000;
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  if (isEmpty) return null;

  if (fieldRender) {
    const value = getFieldValue(name);
    return fieldRender(label, name, inputProps?.rules, options, value);
  }

  switch (inputType) {
    case InputType.BoolRadio:
    case InputType.Radio: {
      const boolOptions = [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ];
      const newOptions =
        inputType === InputType.BoolRadio ? boolOptions : options;

      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <Radio.Group {...inputProps} options={newOptions} />
        </Form.Item>
      );
    }

    case InputType.RadioWithOther:
      return (
        <Form.Item noStyle>
          <Form.Item label={label} name={name} {...formItemProps}>
            <Radio.Group {...inputProps} options={options} />
          </Form.Item>
          <Form.Item
            shouldUpdate={(preValues, currValues) =>
              preValues[name] !== currValues[name]
            }
            noStyle
          >
            {() => {
              const formValue = getFieldValue(name);
              const formInput = getOtherInput(formValue, options);
              return formInput ? (
                otherOptions ? (
                  <Form.Item
                    name={otherName}
                    label={otherLabel}
                    key={formInput.name}
                    messageVariables={{ label: label ? label : "" }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder="Please select"
                      options={otherOptions}
                      {...inputProps}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name={otherName ? otherName : formInput.name}
                    label={otherLabel ? otherLabel : formInput.label}
                    key={formInput.name}
                    rules={[{ required: true, whitespace: true }]}
                    messageVariables={{ label: label ? label : "" }}
                  >
                    <Input
                      placeholder={otherLabel ? otherLabel : formInput.label}
                      maxLength={inputMaxLength}
                    />
                  </Form.Item>
                )
              ) : null;
            }}
          </Form.Item>
        </Form.Item>
      );
    case InputType.BoolSelect:
    case InputType.Select: {
      const boolOptions = [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ];
      const newOptions =
        inputType === InputType.BoolSelect ? boolOptions : options;
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <Select
            allowClear
            showSearch
            placeholder="Please select"
            {...inputProps}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!
                .label!.toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            options={newOptions}
          />
        </Form.Item>
      );
    }

    case InputType.SelectWithOther:
      return (
        <Form.Item noStyle>
          <Form.Item label={label} name={name} {...formItemProps}>
            <Select
              allowClear
              showSearch
              placeholder="Please select"
              {...inputProps}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!
                  .label!.toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              options={options}
            />
          </Form.Item>
          <Form.Item
            shouldUpdate={(preValues, currValues) =>
              preValues[name] !== currValues[name]
            }
            noStyle
          >
            {() => {
              if (options) {
                let Describe;
                const formValue = getFieldValue(name);
                const index = options.findIndex(
                  (v: any) => v.value === formValue && v.describe
                );
                if (index !== -1) {
                  Describe = options[index].describe;
                }
                return Describe ? (
                  <Form.Item>
                    <span style={{ color: "red", fontWeight: 600 }}>
                      {Describe}
                    </span>
                  </Form.Item>
                ) : null;
              }
            }}
          </Form.Item>
          <Form.Item
            shouldUpdate={(preValues, currValues) =>
              preValues[name] !== currValues[name]
            }
            noStyle
          >
            {() => {
              let formValue: any = getFieldValue(name);
              if (formValue instanceof Array) {
                formValue = formValue[0];
              }
              const formInput = getOtherInput(formValue, options);
              return formInput ? (
                <Form.Item
                  name={otherName ? otherName : formInput.name}
                  label={otherLabel ? otherLabel : formInput.label}
                  key={formInput.name}
                  rules={[{ required: true, whitespace: true }]}
                  messageVariables={{ label: label ? label : "" }}
                >
                  <Input
                    placeholder={otherLabel ? otherLabel : formInput.label}
                    maxLength={inputMaxLength}
                  />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
        </Form.Item>
      );

    // case InputType.TreeSelect:
    //   return (
    //     <Form.Item label={label} name={name}  {...formItemProps}>
    //       <TreeSelect
    //         showSearch
    //         placeholder="Please select"
    //         {...inputProps}
    //         optionFilterProp="children"
    //         filterOption={(input, option) =>
    //           option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //         }
    //         treeData={options}
    //       />
    //     </Form.Item>
    //   );

    case InputType.CheckBoxWithOther:
      return (
        <Form.Item noStyle>
          <Form.Item label={label} name={name} {...formItemProps}>
            <Checkbox.Group {...inputProps} style={{ width: "100%" }}>
              <Row gutter={[12, 12]}>
                {options.map((o) => (
                  <Col span={8} key={"Col." + o.value}>
                    <Checkbox value={o.value} key={o.value + "Checkbox"}>
                      {o.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            shouldUpdate={(preValues, currValues) =>
              preValues[name] !== currValues[name]
            }
            noStyle
          >
            {() => {
              const formValue = getFieldValue(name);
              const formArr = getOtherArr(formValue, options);
              return (
                <Row gutter={[12, 12]}>
                  {formArr.map(
                    ({
                      secondLabel,
                      secondName,
                    }: {
                      secondLabel: any;
                      secondName: any;
                    }) => (
                      <Col span={8} key={secondLabel + secondName}>
                        <Form.Item
                          label={otherLabel ? otherLabel : secondLabel}
                          name={otherName ? otherName : secondName}
                          key={secondName}
                          rules={[{ required: true, whitespace: true }]}
                        >
                          <Input maxLength={inputMaxLength} />
                        </Form.Item>
                      </Col>
                    )
                  )}
                </Row>
              );
            }}
          </Form.Item>
        </Form.Item>
      );

    case InputType.CheckBox:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <Checkbox.Group {...inputProps}>
            <Space wrap>
              <Row gutter={[12, 12]}>
                {options.map((o) => (
                  <Col span={8} key={"Col." + o.value}>
                    <Checkbox value={o.value} key={o.value}>
                      {o.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Space>
          </Checkbox.Group>
        </Form.Item>
      );

    case InputType.SingleCheckBox:
      return (
        <Form.Item
          label={label}
          name={name}
          valuePropName="checked"
          {...formItemProps}
        >
          <Checkbox {...inputProps} />
        </Form.Item>
      );

    case InputType.RangePicker:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <RangePicker
            format={process.env.REACT_APP_DATE_FORMAT}
            {...inputProps}
          />
        </Form.Item>
      );

    case InputType.TimeRangePicker:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <TimePicker.RangePicker
            placeholder={["開始時間", "結束時間"]}
            format={process.env.REACT_APP_DATETIME_FORMAT}
            {...inputProps}
          />
        </Form.Item>
      );

    case InputType.DatePicker:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <DatePicker
            format={(value) => value.format(process.env.REACT_APP_DATE_FORMAT)}
            {...inputProps}
          />
        </Form.Item>
      );

    case InputType.DateTimePicker:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <DatePicker
            {...inputProps}
            format={(value) =>
              value.format(process.env.REACT_APP_DATETIME_FORMAT)
            }
            showTime={{ format: process.env.REACT_APP_DATETIME_FORMAT }}
          />
        </Form.Item>
      );

    case InputType.TimePicker:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <TimePicker
            {...inputProps}
            format={(value) =>
              value.format(process.env.REACT_APP_DATETIME_FORMAT)
            }
          />
        </Form.Item>
      );

    case InputType.Switch:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <Switch {...inputProps} />
        </Form.Item>
      );

    case InputType.InputNumber:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <InputNumber {...inputProps} />
        </Form.Item>
      );

    case InputType.Password:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <Input.Password
            {...inputProps}
            placeholder="Please input password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            maxLength={inputMaxLength}
          />
        </Form.Item>
      );

    case InputType.AutoComplete:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <AutoComplete
            placeholder="Please input"
            options={options}
            filterOption={(inputValue, option) =>
              option!.label.indexOf(inputValue) !== -1
            }
          >
            <TextArea autoSize={{ minRows: 5, maxRows: 8 }} />
          </AutoComplete>
        </Form.Item>
      );
    case InputType.AutoInput:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <AutoComplete
            allowClear
            placeholder="Please input"
            options={options}
            filterOption={(inputValue, option) =>
              option!.label !== null &&
              option!.label!.toString().toLowerCase().indexOf(inputValue) !== -1
            }
            {...inputProps}
          >
            <Input></Input>
          </AutoComplete>
        </Form.Item>
      );

    case InputType.TextArea:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <TextArea
            placeholder="Please input..."
            maxLength={textAreaMaxLength}
            autoSize={{ minRows: 5, maxRows: 8 }}
            {...inputProps}
          />
        </Form.Item>
      );

    case InputType.TimeSlot:
      const dayOptions = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      const timeOptions = ["Morning", "Afternoon", "Evening"];
      const colProps = {
        span: 3,
      };
      return (
        <Form.Item noStyle>
          <Form.Item label={label}>
            <Checkbox {...inputProps}>勾選全部</Checkbox>
          </Form.Item>
          <Form.Item name={name} {...formItemProps}>
            <Checkbox.Group {...inputProps} style={{ width: "100%" }}>
              <Row>
                <Col {...colProps}>時間</Col>
                {dayOptions.map((day) => (
                  <Col {...colProps} key={day}>
                    {day}
                  </Col>
                ))}
              </Row>

              {timeOptions.map((time) => (
                <Row key={time}>
                  <Col {...colProps}>{time}</Col>
                  {dayOptions.map((day) => (
                    <Col {...colProps} key={time + day}>
                      <Checkbox value={day + "," + time} />
                    </Col>
                  ))}
                </Row>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Form.Item>
      );

    case InputType.Input:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <Input
            placeholder="Please input"
            maxLength={inputMaxLength}
            {...inputProps}
          />
        </Form.Item>
      );

    case InputType.Rate:
      return (
        <Form.Item label={label} name={name} {...formItemProps}>
          <Rate {...inputProps} />
        </Form.Item>
      );

    default:
      return null;
  }
};

export default CustomFormItem;
