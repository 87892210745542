import { useEffect, useReducer } from "react";
import StoreContext from "./StoreContext";
import { LoadingScreen } from "@library";
import { MemberInfoDto } from "@api";
import { useAuth } from "react-oidc-context";
import { GetCurrent as GetCurrentMemberInfo } from "@components/Members/MemberAPI";

enum ActionKind {
  isLoading,
  currentMember,
  breadcrumb,
}

interface IState {
  isLoading: boolean;
  currentMember: MemberInfoDto;
  breadcrumb?: string;
}

type IReducerAction = {
  type: ActionKind;
  payload?: any;
};

interface IStoreProvider {
  children: JSX.Element;
}

const StoreProvider = ({ children }: IStoreProvider) => {
  const auth = useAuth();

  const initialState: IState = {
    isLoading: false,
    currentMember: {
      id: auth.user?.profile.sub,
      englishName: auth.user?.profile.name,
    },
  };

  const reducer = (
    state: IState,
    { type, payload }: IReducerAction
  ): IState => {
    switch (type) {
      case ActionKind.isLoading:
        return {
          ...state,
          isLoading: payload,
        };
      case ActionKind.currentMember:
        return {
          ...state,
          currentMember: payload,
        };
      case ActionKind.breadcrumb:
        return {
          ...state,
          breadcrumb: payload,
        };
      default:
        return state;
    }
  };

  const [{ isLoading, currentMember, breadcrumb }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const fetchCurrentMember = async () => {
    var member = await GetCurrentMemberInfo();
    dispatch({ type: ActionKind.currentMember, payload: member });
  };
  // 清除过期数据
  function clearExpiredData() {
    // 获取当前时间戳
    const currentTime = Date.now();

    // 遍历 localStorage 中的数据，判断数据是否过期
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        const value = localStorage.getItem(key);

        // 如果数据过期，则从 localStorage 中删除该数据
        if (value && currentTime - Number(value) >= 7 * 24 * 60 * 60 * 1000) {
          // 设定过期时间为一周
          localStorage.removeItem(key!);
        }
      }
    }
  }

  useEffect(() => {
    const callAPI = async () => {
      await fetchCurrentMember();
    };
    if (auth.user?.profile.sub) {
      callAPI();
    }
  }, [auth.user?.profile.sub]);

  // 每天定时清除过期数据
  setInterval(() => {
    clearExpiredData();
  }, 24 * 60 * 60 * 1000);

  return (
    <StoreContext.Provider
      value={{
        currentMember: currentMember,
        logout: () => {
          auth.removeUser();
          auth.signoutRedirect();
        },
        breadcrumb: breadcrumb,

        setIsLoading: (value) => {
          dispatch({ type: ActionKind.isLoading, payload: value });
        },
        setBreadcrumb: (value) => {
          dispatch({ type: ActionKind.breadcrumb, payload: value });
        },
      }}
    >
      {isLoading && <LoadingScreen />}
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
