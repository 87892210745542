import React from "react";
import { Tag, Table, Space, Rate } from "antd";
import { CheckCircleOutlined, SwapRightOutlined } from "@ant-design/icons";
import { YesOrNoIcon } from "@library";
import { getTimeSlot, formatDate, formatTime } from "@actions";
import { InputType } from "@types";

interface ICustomDescriptionsItem {
  inputType: InputType;
  value: any;
  data?: any;
  options?: IOption[];
  link?: string;
  isEmpty?: boolean;
  inputProps?: any;
  valueRender?(value?: any, data?: any, options?: IOption[]): JSX.Element;
}

const CustomDescriptionsItem = ({
  inputType = InputType.Input,
  value,
  options = [],
  link,
  data,
  isEmpty = false,
  valueRender,
  inputProps,
}: ICustomDescriptionsItem) => {
  if (isEmpty) return null;
  if (value === null || typeof value === "undefined") {
    return "-";
  }

  if (valueRender) {
    return valueRender(value, data, options);
  }

  switch (inputType) {
    case InputType.BoolRadio:
      return (
        <div>
          <YesOrNoIcon value={value} />
        </div>
      );

    case InputType.Radio:
    case InputType.Select:
      if (Array.isArray(value)) {
        let result: string = "";
        value.map((item) => {
          const index = options.findIndex((key) => key.value === item);
          if (index !== -1) {
            if (result) {
              result = result + "," + options[index]["label"];
            } else {
              result = result + options[index]["label"];
            }
          }
        });
        return result === "" ? "-" : result;
      } else {
        const index = options.findIndex((key) => key.value === value);
        return index !== -1 ? options[index]["label"] : "-";
      }
    case InputType.DatePicker:
      return formatDate(value);

    case InputType.DateTimePicker:
      return formatDate(value);

    case InputType.TimePicker:
      return formatTime(value);

    case InputType.TimeRangePicker:
      return (
        <div>
          {formatTime(value[0])}
          <SwapRightOutlined />
          {formatTime(value[1])}
        </div>
      );

    case InputType.CheckBoxWithOther:
      return (
        <Space wrap>
          {value.map((item: any, index: any) => (
            <Tag
              color="green"
              icon={<CheckCircleOutlined />}
              key={item + index}
            >
              {item}
            </Tag>
          ))}
        </Space>
      );

    case InputType.CheckBox:
      let arr: IOption[] = [];
      if (Array.isArray(value)) {
        arr = options.filter(
          (item) => value.findIndex((key) => key === item.value) !== -1
        );
      }
      return (
        <Space wrap>
          {arr.map((item) => (
            <Tag color="green" icon={<CheckCircleOutlined />} key={item.value}>
              {item.label}
            </Tag>
          ))}
        </Space>
      );

    case InputType.Switch:
      return (
        <div>
          <YesOrNoIcon value={value} />
        </div>
      );
    // case InputType.TimeSlot:
    //   const columns = [
    //     {
    //       title: "時間",
    //       dataIndex: "time",
    //     },
    //     {
    //       title: "Mon",
    //       dataIndex: "Mon",
    //       render: (value: boolean) => value && <YesOrNoIcon value={value} />,
    //     },
    //     {
    //       title: "Tue",
    //       dataIndex: "Tue",
    //       render: (value: boolean) => value && <YesOrNoIcon value={value} />,
    //     },
    //     {
    //       title: "Wed",
    //       dataIndex: "Wed",
    //       render: (value: boolean) => value && <YesOrNoIcon value={value} />,
    //     },
    //     {
    //       title: "Thu",
    //       dataIndex: "Thu",
    //       render: (value: boolean) => value && <YesOrNoIcon value={value} />,
    //     },
    //     {
    //       title: "Fri",
    //       dataIndex: "Fri",
    //       render: (value: boolean) => value && <YesOrNoIcon value={value} />,
    //     },
    //     {
    //       title: "Sat",
    //       dataIndex: "Sat",
    //       render: (value: boolean) => value && <YesOrNoIcon value={value} />,
    //     },
    //     {
    //       title: "Sun",
    //       dataIndex: "Sun",
    //       render: (value: boolean) => value && <YesOrNoIcon value={value} />,
    //     },
    //   ];
    //   const newValue = getTimeSlot(value);
    //   const timeOptions = ["Morning", "Afternoon", "Evening"];
    //   let dataSource = [];
    //   for (let time in timeOptions) {
    //     const filteredDays = newValue.filter((item: any) => {
    //       return item.time === timeOptions[time];
    //     });
    //     let tempObj = {};

    //     tempObj["time"] = timeOptions[time];
    //     filteredDays.forEach(({ day }) => {
    //       tempObj[day] = true;
    //     });

    //     dataSource.push(tempObj);
    //   }
    //   return (
    //     <Table
    //       rowKey="time"
    //       style={{ width: "100%" }}
    //       columns={columns}
    //       dataSource={dataSource}
    //       pagination={false}
    //     />
    //   );

    case InputType.AutoComplete:
    case InputType.TextArea:
      return <pre style={{ whiteSpace: "pre-wrap" }}>{value}</pre>;

    case InputType.Rate:
      return <Rate {...inputProps} value={value} disabled />;

    default:
      if (link) return <a href={link}>{value}</a>;
      return value;
  }
};

export default CustomDescriptionsItem;
