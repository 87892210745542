import NotFoundPage from "@pages/404";
import IndexPage from "@pages/index";
import CreatePage from "@/pages/create";
import DetailPage from "@pages/detail";
import CompletedPage from "@pages/completed";

const RouteList: IRouteList[] = [
  {
    path: "/",
    title: "Forms",
    key: "index",
    subItem: [
      {
        title: "Form List",
        key: "form-list",
        path: "/",
        element: <IndexPage />,
      },
      {
        key: "completed",
        path: "/completed",
        element: <CompletedPage />,
      },

      {
        key: "form-detail",
        path: "/detail/:id",
        element: <DetailPage />,
      },
      {
        key: "create",
        path: "/create/:id",
        element: <CreatePage />,
      },
    ],
  },
  {
    path: "/404",
    key: "404",
    element: <NotFoundPage />,
  },
];

export default RouteList;
