import { AuthProvider } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

const AuthenticationProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <AuthProvider
      onSigninCallback={(user) => {
        window.history.replaceState(
          {},
          window.document.title,
          window.location.origin + window.location.pathname
        );
        let state = user?.state as { returnUrl: string };

        if (state?.returnUrl) {
          navigate(decodeURIComponent(state.returnUrl));
        } else {
          navigate("/");
        }
      }}
      metadataUrl={`${process.env.REACT_APP_OIDC_METADATA_URL}`}
      authority={`${process.env.REACT_APP_OIDC_AUTHORITY}`}
      client_id={`${process.env.REACT_APP_OIDC_CLIENTID}`}
      redirect_uri={window.location.origin + "/authentication/login-callback"}
      scope={`${process.env.REACT_APP_OIDC_SCOPE}`}
      post_logout_redirect_uri={window.location.origin}
    >
      {children}
    </AuthProvider>
  );
};
//https://refreshuat.b2clogin.com/refreshuat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=ed9c8af7-01ca-4add-a046-4538a51954ba&nonce=defaultNonce&redirect_uri=https%3A%2F%2Flocalhost%3A44414%2Fauthentication%2Flogin-callback&scope=openid%20https%3A%2F%2Frefreshuat.onmicrosoft.com%2Fed9c8af7-01ca-4add-a046-4538a51954ba%2Fuser&response_type=code&prompt=login
export default AuthenticationProvider;
