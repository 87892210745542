import React from "react";
import { Row, Col, Form, Input } from "antd";
import { SearchButton, ResetButton, FlexEndSpace } from "@library";
import InputField from "./InputField";
import { inputMaxLength } from "@objects";
import { ISearchOption } from "@types";

interface IInlineAdvanceSearchForm {
  isLoading: boolean;
  searchOptions: ISearchOption[];
  onSearch(value: any): void;
}

const InlineAdvanceSearchForm = ({
  searchOptions,
  onSearch,
  isLoading,
}: IInlineAdvanceSearchForm) => {
  const [form] = Form.useForm(undefined);

  return (
    <Form form={form} onFinish={onSearch} layout="vertical">
      <Row gutter={[24, 24]}>
        {searchOptions.map(({ fields, value, label, span }) => {
          const colProps = span
            ? {
                span: span * 6,
              }
            : {
                xl: 6,
                sm: 8,
                xs: 12,
              };
          return fields?.length ? (
            fields.map((props, index) => (
              <Col key={`${value}${index}`} {...colProps}>
                <InputField
                  {...props}
                  isAdvanceForm
                  label={label + `${props.label ? " " + props.label : ""}`}
                />
              </Col>
            ))
          ) : (
            <Col key={value} {...colProps}>
              <Form.Item name={value} label={label}>
                <Input placeholder="Please input" maxLength={inputMaxLength} />
              </Form.Item>
            </Col>
          );
        })}
      </Row>
      <FlexEndSpace>
        <SearchButton loading={isLoading} htmlType="submit" type="primary">
          <>Search</>
        </SearchButton>
        <ResetButton
          loading={isLoading}
          onClick={() => {
            form.resetFields();
            form.submit();
          }}
        />
      </FlexEndSpace>
    </Form>
  );
};

export default InlineAdvanceSearchForm;
