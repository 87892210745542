import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "antd";

const Logo = (): JSX.Element => {
  const { xl } = Grid.useBreakpoint();
  return (
    <Link to="/">
      <img
        src={xl ? "/logo.svg" : "/logo-collapsed.svg"}
        alt="Bokss"
        style={{
          width: xl ? "260px" : "50px",
          padding: xl ? "10px 15px" : "6px 3px 6px 8px",
          background: "#fff",
          transform: "translateY(-10px)",
          borderRadius: "6px",
        }}
      />
    </Link>
  );
};

export default Logo;
