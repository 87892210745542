import React, { useState, useEffect } from "react";
import { Switch, Form, Select } from "antd";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import SelectedFormItem from "./SelectedFormItem";
import { ISearchOption } from "@types";
import { ResetButton, SearchButton } from "@library";

interface ISearchForm {
  isLoading: boolean;
  searchOptions: ISearchOption[];
  extra: JSX.Element[] | JSX.Element;
  hiddenAdvance?: boolean;
  hiddenSearch?: boolean;
  isAdvanced?: boolean;
  setIsAdvanced?: React.Dispatch<React.SetStateAction<boolean>>;
  onFinish(value: any): void;
  isDefault?: boolean;
  isAdvancedSearch?: boolean;
}

const SearchForm = ({
  hiddenSearch = true,
  hiddenAdvance = false,
  extra,
  isAdvanced = false,
  onFinish,
  isLoading,
  isDefault = false,
  searchOptions,
  setIsAdvanced = () => {},
  isAdvancedSearch,
}: ISearchForm) => {
  const [form] = Form.useForm(undefined);
  const [currentInput, setCurrentInput] = useState<any>(null);
  const [isFetch, setIsFetch] = useState(false);

  const handleFinish = (values: any) => {
    if ("inputValue" in values) {
      const { selectField, inputValue } = values;
      const newField = selectField ?? "textFilter";
      onFinish({ [newField]: inputValue });
    } else {
      onFinish(values);
    }
  };

  const onValuesChange = (changedValues: any) => {
    if ("selectField" in changedValues) {
      const newCurrentInput = searchOptions.find(
        (item) => item.value === changedValues["selectField"]
      );
      setCurrentInput(newCurrentInput);
    }
  };

  useEffect(() => {
    if (!currentInput?.value && isFetch) {
      form.resetFields();
      form.submit();
    }
  }, [currentInput, isFetch]);

  useEffect(() => {
    if (isDefault && searchOptions.length > 0) {
      form.setFieldsValue({ selectField: searchOptions[0].value });
      setCurrentInput(searchOptions[0]);
    }
  }, [isDefault, searchOptions]);

  return (
    <Form
      className="advance-search-form"
      layout="inline"
      onFinish={handleFinish}
      form={form}
      onValuesChange={onValuesChange}
    >
      {!hiddenAdvance && isAdvancedSearch && (
        <Form.Item label="Advanced Search">
          <Switch
            checked={isAdvanced}
            onChange={(values) => {
              setIsAdvanced(values);
            }}
          />
        </Form.Item>
      )}
      {!hiddenSearch && searchOptions.length ? (
        <>
          <Form.Item
            name="selectField"
            // label="By"
          >
            <Select
              size="middle"
              placeholder="Please select"
              disabled={isAdvanced}
              options={searchOptions}
              loading={isLoading}
              allowClear
            />
          </Form.Item>
          <SelectedFormItem
            fields={currentInput?.fields}
            isAdvanced={isAdvanced || isLoading}
          />
          <Form.Item>
            <SearchButton
              disabled={isAdvanced}
              type="primary"
              loading={isLoading}
              onClick={() => form.submit()}
            >
              <>Search</>
            </SearchButton>
          </Form.Item>
          <Form.Item>
            <ResetButton
              disabled={isAdvanced}
              loading={isLoading}
              onClick={() => {
                setCurrentInput(null);
                setIsFetch(true);
              }}
              icon={<RedoOutlined />}
              danger
            />
          </Form.Item>
        </>
      ) : undefined}

      {Array.isArray(extra)
        ? extra?.map((ExtraItem, index) => (
            <Form.Item key={index}>{ExtraItem}</Form.Item>
          ))
        : extra}
    </Form>
  );
};

export default SearchForm;
