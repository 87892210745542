import { useEffect, useReducer, useContext } from "react";
import { StoreContext } from "@providers";
import { Button, Form, Card, Space } from "antd";
import { CustomFormDescriptions } from "@library";
import { FieldsType, InputType } from "@types";
import { useParams, useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { SurveyDto } from "@api";
import { Get } from "@components/Surveys/SurveyAPI";
import { SurveyContent } from "@components/Surveys";
import { getAssessmentTypeTitle } from "@actions";

enum ActionKind {
  isLoading,
  pageChange,
  params,
  initPage,
  fetchData,
  survey,
}

interface IState {
  isLoading: boolean;
  survey?: SurveyDto;
}

type IReducerAction = {
  type: ActionKind;
  payload?: any;
};

const DetailPage = () => {
  const { setBreadcrumb } = useContext(StoreContext);
  const params = useParams();
  const navigate = useNavigate();
  const surveyId = params.id;
  const [form] = Form.useForm(undefined);
  const initialState: IState = {
    isLoading: false,
  };

  const reducer = (
    state: IState,
    { type, payload }: IReducerAction
  ): IState => {
    switch (type) {
      case ActionKind.isLoading:
        return {
          ...state,
          isLoading: payload,
        };

      case ActionKind.survey:
        return {
          ...state,
          survey: payload,
        };

      default:
        return state;
    }
  };

  const [{ isLoading, survey }, dispatch] = useReducer(reducer, initialState);

  const fields: FieldsType[] = [
    {
      label: "Name of form",
      name: "formName",
      inputType: InputType.Input,
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      label: "Form Type",
      name: "formTypeName",
      inputType: InputType.Input,
      formItemProps: {
        rules: [{ required: true }],
      },
      valueRender(value) {
        return <>{getAssessmentTypeTitle(value)}</>;
      },
    },
  ];

  const fetchSurvey = async (id: string) => {
    const data = await Get(id);
    dispatch({ type: ActionKind.survey, payload: data });
    form.setFieldsValue({ ...data });
    setBreadcrumb(data.formName);
  };

  useEffect(() => {
    const init = async (id: string) => {
      await fetchSurvey(id);
    };
    if (surveyId) {
      init(surveyId);
    }
  }, [surveyId]);

  return (
    <Card
      loading={isLoading}
      extra={
        <Space>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              navigate(`/create/${surveyId}`);
            }}
          >
            Fill in
          </Button>
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </Space>
      }
    >
      {survey && (
        <>
          <CustomFormDescriptions
            isEditing={false}
            fields={fields}
            getFieldValue={form.getFieldValue}
            data={{
              ...survey,
              formType: getAssessmentTypeTitle(survey.formType!),
            }}
          />

          <SurveyContent
            json={survey!.content!}
            mode="display"
            onComplete={(value) => {}}
          />
        </>
      )}
    </Card>
  );
};
export default DetailPage;
