import { Route, Routes } from "react-router-dom";
import RouteList from "./RouteList";

const AppRoutes = () => {
  return (
    <Routes>
      {RouteList.map((item) => {
        let routes: JSX.Element[] = [];
        if (item.subItem)
          routes = item.subItem?.map((x, i) => <Route {...x}></Route>);
        if (item.path) routes.push(<Route {...item}></Route>);
        return routes;
      })}
    </Routes>
  );
};
export default AppRoutes;
