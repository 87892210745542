import React, { useState, useContext } from "react";
import { Layout, Space, Grid, Dropdown } from "antd";
import { getHeadMenuKey } from "@actions";
import { MenuButtons, SubMenuButtons, Logo, SettingMenu } from ".";
import { RouteList } from "@routes";
import { UserOutlined, SettingOutlined } from "@ant-design/icons";
import { Button } from "@library";
import { StoreContext } from "@providers";

// interface IHeader {
//   children: JSX.Element;
// }

const Header = (): JSX.Element => {
  const { xl } = Grid.useBreakpoint();
  const { Header: AntHeader } = Layout;
  const pathname = window.location.pathname;
  const headMenuKey = getHeadMenuKey(RouteList, pathname);
  const [currentKey, setCurrentKey] = useState(headMenuKey);
  const { currentMember } = useContext(StoreContext);

  return (
    <div className="header-container">
      <header
        style={{
          background: "#0090d5",
          color: "#fff",
          fontWeight: "700",
          fontSize: "16px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: xl ? "0px 30px" : "0px 10px",
        }}
      >
        <Space size="large" style={{ fontSize: "16px" }}>
          <div>
            <UserOutlined />
            &nbsp; {currentMember.englishName}
          </div>
          {/* 
          <div>
            System No : &nbsp;
            {currentMember.memberNo ? currentMember?.memberNo : "-"}
          </div> */}
        </Space>
      </header>
      <AntHeader style={{ background: "#0090d5", padding: "0px 30px" }}>
        <Space style={{ marginRight: xl ? "30px" : "0" }}>
          <Logo />
        </Space>
        {/* <MenuButtons
          menus={RouteList}
          currentKey={currentKey}
          setCurrentKey={setCurrentKey}
        /> */}

        <div style={{ flexGrow: 1 }} />
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          overlay={<SettingMenu />}
        >
          <Button size="large" icon={<SettingOutlined />} ghost />
        </Dropdown>
      </AntHeader>
      {/* 
      <AntHeader style={{ background: "#fff", padding: "0px 30px" }}>
        <SubMenuButtons
          subMenus={RouteList.find((x) => x.key === currentKey)?.subItem}
        />
      </AntHeader> */}
    </div>
  );
};

export default Header;
