import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

interface IYesOrNoIcon {
  value: boolean;
}
const YesOrNoIcon = ({ value }: IYesOrNoIcon) => {
  if (value) {
    return <CheckCircleOutlined style={{ color: "green" }} />;
  } else {
    return <CloseCircleOutlined style={{ color: "red" }} />;
  }
};
export default YesOrNoIcon;
