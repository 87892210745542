import React, {
  useReducer,
  useEffect,
  useImperativeHandle,
  Ref,
  useCallback,
  forwardRef,
} from "react";
import { ITableColumn } from "@types";
import { Table } from "@library";
import { formatDate } from "@actions";
import { SurveyDto, SearchSurveysWithPaginationQuery } from "@api";
import { Search } from "../SurveyAPI";

enum ActionKind {
  isLoading,
  dataSource,
  fetchData,
  pageChange,
  init,
}

interface IState {
  isLoading: boolean;
  params: SearchSurveysWithPaginationQuery;
  dataSource: SurveyDto[];
  pageMeta: IPageMeta;
}
type IReducerAction = {
  type: ActionKind;
  payload: any;
};

interface ISurveyTable {
  memberId: string;
  columnsAction?: ITableColumn<SurveyDto>;
}

const SurveysTable = forwardRef(
  ({ memberId, columnsAction = [] }: ISurveyTable, ref): JSX.Element => {
    const initialState: IState = {
      isLoading: false,
      params: {
        orderBy: "created",
        isOrderByAscending: false,
        pageNumber: 1,
        pageSize: 10,
      },
      dataSource: [],
      pageMeta: { current: 1, total: 0, pageSize: 0 },
    };
    const reducer = (
      state: IState,
      { type, payload }: IReducerAction
    ): IState => {
      switch (type) {
        case ActionKind.isLoading:
          return {
            ...state,
            isLoading: payload,
          };
        case ActionKind.fetchData:
          return {
            ...state,
            isLoading: false,
            dataSource: payload?.items,
            pageMeta: {
              current: payload?.pageNumber,
              total: payload?.totalCount,
              pageSize: state.params.pageSize,
            },
          };
        case ActionKind.pageChange:
          return {
            ...state,
            params: {
              ...state.params,
              ...payload,
            },
          };
        case ActionKind.init:
          return {
            ...initialState,
          };

        default:
          return state;
      }
    };
    const [{ dataSource, isLoading, pageMeta, params }, dispatch] = useReducer(
      reducer,
      initialState
    );

    const columns: ITableColumn<SurveyDto> = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: "Contact No.",
        dataIndex: "contactNo",
        key: "contactNo",
        sorter: true,
      },
      {
        title: "Position",
        dataIndex: "position",
        key: "position",
        sorter: true,
      },
      {
        title: "Agency",
        dataIndex: "agency",
        key: "agency",
        sorter: true,
      },
      ...columnsAction,
    ];

    useImperativeHandle(ref, () => ({
      fetchData,
    }));

    const fetchData = useCallback(() => {
      const callAPI = async (
        searchParams: SearchSurveysWithPaginationQuery
      ) => {
        try {
          dispatch({ type: ActionKind.isLoading, payload: true });
          const result = await Search(searchParams);

          dispatch({ type: ActionKind.fetchData, payload: result });
        } catch (error) {
          dispatch({ type: ActionKind.isLoading, payload: false });
        }
      };

      callAPI(params);
    }, [params]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        onPageChange={(value: IPageMeta) =>
          dispatch({ type: ActionKind.pageChange, payload: value })
        }
        pageMeta={pageMeta}
      />
    );
  }
);
export default SurveysTable;
