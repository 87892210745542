import { Button, ButtonProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface ISearchButton extends ButtonProps {
  children?: any;
  href?: string;
}

const SearchButton = ({ href, children, ...rest }: ISearchButton) => {
  const navigate = useNavigate();

  return (
    <Button
      icon={<SearchOutlined />}
      onClick={() => {
        if (href) navigate(href);
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SearchButton;
