import { message, Space, notification } from "antd";
import moment from "moment";
import { AssessmentType } from "@api";
import { FieldsType, InputType } from "@types";

export const showError = (errMsg: any) => {
  try {
    switch (errMsg.status) {
      case 404:
        message.warning("No relevant records found.");
        return;
      case 403:
        message.warning("No relevant permission.");
        return;
      case 503:
        message.warning(
          "The system is under maintenance, please try again later."
        );
        return;
      case 500:
        message.error("System error, please try again later.");
        return;

      default:
        break;
    }
    if (!errMsg?.response) return;
    const response = JSON.parse(errMsg.response);
    const errList = response.errors || {};
    if (Object.keys(errList).length === 0) throw new Error();

    message.warning(
      <Space>{Object.values<string[]>(errList).map((msg) => msg)}</Space>
    );
  } catch (err) {
    message.error("System error.");
  }
};

export const getOtherInput = (formValue: any, options: IOption[]) => {
  const index = options.findIndex(
    (option) => option.value === formValue && option.hasRemarks
  );
  if (index !== -1) {
    return { label: options[index].label, name: formValue + "-Remarks" };
  }
  return null;
};

export const getOtherArr = (formValue: any, options: IOption[]) => {
  if (!formValue) {
    return [];
  }

  let result: any = [];
  formValue.forEach((value: any) => {
    const index = options.findIndex(
      (option) => option.value === value && option.hasRemarks
    );
    if (index !== -1) {
      result.push({
        secondLabel: options[index].label,
        secondName: value + "-Remarks",
      });
    }
  });
  return result;
};

export const getTimeSlot = (arr: any) => {
  return arr.map((item: any) => {
    const newArr = item.split(",", 2);
    return { day: newArr[0], time: newArr[1] };
  });
};

export const formatDate = (
  date: string,
  format = process.env.REACT_APP_DATE_FORMAT
) => {
  return date ? moment(date).format(format) : undefined;
};

export const formatDateTime = (
  date: string,
  format = process.env.REACT_APP_DATETIME_FORMAT
) => {
  return date ? moment(date).format(format) : undefined;
};

export const formatTime = (
  time: string,
  format = process.env.REACT_APP_DATETIME_FORMAT
) => (time ? moment(time).format(format) : undefined);

export const formatTitle = (title: string) => {
  const userDefined = [
    {
      key: "Birthdate",
      value: "Date of Birth",
    },
  ];

  const newUserDefined = userDefined.find((i) => {
    return i.key === title;
  });
  if (newUserDefined?.value) {
    return newUserDefined.value;
  }

  let arr = title.split(/(?=[A-Z])/);
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    if (arr[i] === "Or") {
      arr[i] = "/";
    }
    if (arr[i] === "And") {
      arr[i] = "and";
    }
  }

  const newArr = [];
  let res = "";
  for (let i = 0; i < arr.length; i++) {
    const reg = /^[A-Z]+$/;
    if (reg.test(arr[i])) {
      res += arr[i];
    } else {
      if (res.length > 0) {
        newArr[newArr.length] = res;
        newArr[newArr.length] = arr[i];
        res = "";
      } else {
        newArr[newArr.length] = arr[i];
      }
    }
  }

  return newArr.join(" ");
};

export const getHeadMenuKey = (objList: any, pathname: string): string => {
  let key = "index";
  objList
    .filter((item: any) => item.children)
    .forEach((item: any) => {
      if (pathname.includes(item.path!)) {
        key = item.key;
        return;
      }

      let index = item.children?.findIndex((x: any) =>
        pathname.includes(x.path!)
      );

      if (index !== -1) {
        key = item.key;
        return;
      }
    });

  return key;
};

export const getUserDefinedValue = (values: any) => {
  let key = null;
  let arr = [];
  for (key in values) {
    if (key.includes("UserDefinedValue") && !!values[key]) {
      const tempValue = [values[key]];
      const comCodeArr = tempValue.map((item) => {
        return {
          comCodeId: item.value,
          remarks: values[item.value + "-Remarks"],
        };
      });

      const currArr: any = arr.concat(comCodeArr);
      arr = currArr;
    }
  }
  return arr;
};

// export const dataToInputUserDefinedValue = (
//   key: UserDefinedField,
//   values: any
// ) => {
//   let arr: UserDefinedValueInputDto[] = [];
//   if (Array.isArray(values)) {
//     values.forEach((o: any) => {
//       arr.push({ userDefinedValueId: o });
//     });
//   } else {
//     arr.push({ userDefinedValueId: values });
//   }

//   return { [key]: [...arr] };
// };

export const dataToReadUserDefinedValue = (
  fields: FieldsType[],
  values: any
) => {
  const arr = fields.filter((item) =>
    item["name"]?.includes("-UserDefinedValue")
  );

  let result: any = {};

  for (let i in arr) {
    const tempArr = values
      .filter((item: any) => {
        if (item.userDefinedValue) {
          return (
            arr[i].name.replace("-UserDefinedValue", "").toUpperCase() ===
            item.userDefinedValue.category!.toUpperCase()
          );
        }
        return false;
      })
      .map((item: any) => item.userDefinedValue.id);

    result[arr[i].name] =
      arr[i].inputType === InputType.Select ||
      arr[i].inputType === InputType.SelectWithOther ||
      arr[i].inputType === InputType.Radio ||
      arr[i].inputType === InputType.RadioWithOther
        ? tempArr[0]
        : tempArr;
  }

  const remarkArr = values.filter((item: any) => !!item.remarks);

  for (let j in remarkArr) {
    if (remarkArr[j].userDefinedValue?.id) {
      result[remarkArr[j].userDefinedValue!.id + "-Remarks"] =
        remarkArr[j].remarks;
    }
  }

  return result;
};

export const normFile = (fileList: any) => {
  if (fileList?.fileList.length) {
    const file = fileList.fileList[0];
    if (file.size / 1024 / 1024 > 10) {
      message.error("The file cannot exceed 10 Mbs");
      return null;
    }

    return { data: file.originFileObj, fileName: file.name };
  }

  return null;
};

export const downloadFile = (file: any) => {
  if (!file) return;
  const filename = file.fileName;
  if (filename !== undefined) {
    const fileTypeIndex = filename.lastIndexOf(".");
    if (fileTypeIndex !== -1) {
      const fileType = filename.slice(fileTypeIndex + 1);
      const fileLib = [
        { contentType: "image/jpeg", type: "jpg" },
        { contentType: "image/jpeg", type: "jpeg" },
        { contentType: "image/png", type: "png" },
        { contentType: "image/webp", type: "webp" },
        { contentType: "image/gif", type: "gif" },
        { contentType: "application/pdf", type: "pdf" },
      ];
      const target = fileLib.filter((e) => fileType.toLowerCase() === e.type);
      if (target.length) {
        const blob = new Blob([file.data], {
          type: target[0].contentType,
        });
        window.open(URL.createObjectURL(blob), "_blank");
        return;
      }
    }
  }
  const url = window.URL.createObjectURL(file.data);
  const a = document.createElement("a");
  const str = decodeURIComponent(file.headers["content-disposition"]);
  a.href = url;
  a.download = str.split("filename*=UTF-8''").pop()!;
  a.click();
  a.remove();
};

export const ReadHistoryRecord = (oldValues: string, newValues: string) => {
  const before = JSON.parse(oldValues);
  const after = JSON.parse(newValues);

  let result: any = [];
  if (before !== null) {
    for (let i in before) {
      if (
        i !== "Created" &&
        i !== "CreatedBy" &&
        i !== "LastModified" &&
        i !== "LastModifiedBy"
      ) {
        if (after !== null && String(after[i])) {
          result[result.length] = {
            fieldchanged: i,
            before: String(before[i]),
            after: String(after[i]),
          };
        } else {
          result[result.length] = {
            fieldchanged: i,
            before: String(before[i]),
            after: null,
          };
        }
      }
    }
  } else {
    for (let i in after) {
      if (
        i !== "Created" &&
        i !== "CreatedBy" &&
        i !== "LastModified" &&
        i !== "LastModifiedBy"
      ) {
        if (before !== null && String(before[i])) {
          result[result.length] = {
            fieldchanged: i,
            before: String(before[i]),
            after: String(after[i]),
          };
        } else {
          result[result.length] = {
            fieldchanged: i,
            before: null,
            after: String(after[i]),
          };
        }
      }
    }
  }

  return result;
};

export const getAssessmentTypeTitle = (
  assessmentType: AssessmentType | string
) => {
  switch (assessmentType) {
    case AssessmentType.ClinicalOrCaseAssessment:
      return "Clinical / Case Assessment";

    case AssessmentType.RecoveryAssessment:
      return "Recovery Assessment";

    case AssessmentType.VolunteerAssessment:
      return "Volunteer Assessment";

    case AssessmentType.SBUAssessmentResults:
      return "SBU Assessment Results";

    case AssessmentType.VolunteerEvaluationRecord:
      return "Volunteer Evaluation Record";

    case AssessmentType.VolunteerFeedbackForm:
      return "Volunteer Feedback Form";
    case AssessmentType.OtherSurveyOrQuestionnaire:
      return "Others Survey / Questionnaire";
    default:
      return undefined;
  }
};

export const uniqueIOptions = (options: IOption[]): IOption[] => {
  const set = new Set(options.map((option) => option.value));
  return Array.from(set).map(
    (value) => options.find((option) => option.value === value)!
  );
};

/**
 * 显示通知消息
 * @param type - 通知类型
 * @param placement - 弹出位置
 * @param message - 通知提醒标题
 * @param description -通知提醒内容
 * @param duration - 自动关闭时间（单位：秒），默认不传入或配置为 null 则不自动关闭
 * @returns
 */
export const CustomNotification = (
  type: "success" | "info" | "warning" | "error",
  placement:
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight" = "top",
  message: string,
  description: string,
  duration: number | null = null
) => {
  const openNotification = () => {
    notification[type]({
      placement: placement,
      message: message,
      description: description,
      duration: duration,
    });
  };
  // 省略按钮，直接在组件内部调用 openNotification 函数
  openNotification();
  return null;
};
