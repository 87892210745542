import { useContext } from "react";
import { Menu } from "antd";
import { LogoutOutlined, RetweetOutlined } from "@ant-design/icons";
import { StoreContext } from "@providers";

interface ISettingMenu {}

const SettingMenu = ({}: ISettingMenu) => {
  const { logout } = useContext(StoreContext);
  const handleOnClick = ({ key }: any) => {
    switch (key) {
      case "logout":
        logout();
        break;

      default:
        break;
    }
  };

  return (
    <Menu
      onClick={handleOnClick}
      selectedKeys={undefined}
      items={[{ key: "logout", label: "Logout", icon: <LogoutOutlined /> }]}
    />
  );
};

export default SettingMenu;
