import React from "react";
import { Layout } from "antd";

const Footer: React.FC = () => {
  const { Footer: AntFooter } = Layout;
  let currentTime = new Date().getFullYear();

  return (
    <AntFooter style={{ textAlign: "center" }}>
      All rights reserved. ©{currentTime} BOKSS - Case Library&nbsp;
    </AntFooter>
  );
};

export default Footer;
