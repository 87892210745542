import { useEffect, useReducer, useContext } from "react";
import { StoreContext } from "@providers";
import { Button, Form, Card, message } from "antd";
import { CustomFormDescriptions, Modal } from "@library";
import { FieldsType, InputType } from "@types";
import { useNavigate, useParams } from "react-router-dom";
import { SurveyDto } from "@api";
import { Get } from "@components/Surveys/SurveyAPI";
import { SurveyContent } from "@components/Surveys";
import { Create } from "@components/MemberSurveys/MemberSurveyAPI";

enum ActionKind {
  isLoading,
  pageChange,
  params,
  initPage,
  fetchData,
  survey,
  surveyAnswer,
  isVerified,
  isCompleted,
}

interface IState {
  isLoading: boolean;
  survey?: SurveyDto;
  surveyAnswer?: string;
  isVerified: boolean;
  isCompleted: boolean;
}

type IReducerAction = {
  type: ActionKind;
  payload?: any;
};

const CreatePage = ({}) => {
  const { setBreadcrumb, currentMember } = useContext(StoreContext);
  const params = useParams();
  const navigate = useNavigate();

  const surveyId = params.id;
  const [form] = Form.useForm(undefined);
  const initialState: IState = {
    isLoading: false,
    isVerified: true,
    isCompleted: false,
  };

  const fields: FieldsType[] = [
    {
      label: "Password",
      name: "password",
      inputType: InputType.Password,
      span: 3,
      formItemProps: {
        rules: [{ required: true }],
      },
    },
  ];

  const reducer = (
    state: IState,
    { type, payload }: IReducerAction
  ): IState => {
    switch (type) {
      case ActionKind.isLoading:
        return {
          ...state,
          isLoading: payload,
        };

      case ActionKind.survey:
        return {
          ...state,
          survey: payload,
          isVerified: !payload.password,
        };
      case ActionKind.surveyAnswer:
        return {
          ...state,
          surveyAnswer: payload,
        };
      case ActionKind.isVerified:
        return {
          ...state,
          isVerified: payload,
        };
      case ActionKind.isCompleted:
        return {
          ...state,
          isCompleted: payload,
        };

      default:
        return state;
    }
  };

  const [
    { isLoading, survey, surveyAnswer, isVerified, isCompleted },
    dispatch,
  ] = useReducer(reducer, initialState);

  const fetchSurvey = async (id: string) => {
    const data = await Get(id);
    dispatch({ type: ActionKind.survey, payload: data });
    setBreadcrumb(data.formName);
  };

  const onFinish = async (values: any) => {
    try {
      dispatch({ type: ActionKind.isLoading, payload: true });
      if (isVerified) {
        // 获取当前时间戳
        const currentTime = Date.now();

        // 从 localStorage 中获取用户最近一次提交的时间
        const lastSubmitTime = localStorage.getItem(currentMember.id!);
        // 判断上一次提交的时间是否在五分钟之内
        if (
          lastSubmitTime &&
          currentTime - Number(lastSubmitTime) < 5 * 60 * 1000
        ) {
          message.warning(
            "You have already submitted the questionnaire. Please try again in five minutes."
          );
          return;
        }

        await Create({
          ...values,
          memberInfoId: currentMember.id,
          surveyId: survey?.id,
          resultJson: surveyAnswer,
        }).then(() => {
          // navigate("/completed");
          // 如果没有超过五分钟，则更新最近一次提交的时间，并存储到 localStorage 中
          localStorage.setItem(currentMember.id!, String(currentTime));

          dispatch({ type: ActionKind.isCompleted, payload: true });
        });
      } else {
        if (survey?.password === values.password) {
          dispatch({ type: ActionKind.isVerified, payload: true });
        } else {
          message.warning(
            "Password error! Please contact the relevant staff to obtain the correct password."
          );
        }
      }
    } finally {
      dispatch({ type: ActionKind.isLoading, payload: false });
    }
  };

  useEffect(() => {
    const init = async (id: string) => {
      await fetchSurvey(id);
    };
    if (surveyId) {
      init(surveyId);
    }
  }, [surveyId]);

  return (
    <Card
      loading={isLoading}
      extra={
        <Button
          onClick={() => {
            navigate("/");
          }}
        >
          Cancel
        </Button>
      }
    >
      <Form form={form} onFinish={onFinish} autoComplete="off">
        {!isVerified && (
          <Modal
            isNarrow
            title="Verify Password"
            open={!isVerified}
            onOk={() => form.submit()}
            onCancel={() => navigate("/")}
          >
            <CustomFormDescriptions
              isEditing={true}
              fields={fields}
              getFieldValue={form.getFieldValue}
            />
          </Modal>
        )}
        {survey && (
          <SurveyContent
            mode={isVerified ? "edit" : "display"}
            json={survey!.content!}
            showCompletedPage={isCompleted}
            onComplete={(value) => {
              dispatch({
                type: ActionKind.surveyAnswer,
                payload: JSON.stringify(value.valuesHash),
              });
              form.submit();
            }}
          />
        )}
      </Form>
    </Card>
  );
};
export default CreatePage;
