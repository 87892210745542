import { Card } from "antd";

const CompletedPage = () => {
  return (
    <Card>
      <h1>Congratulations on completing the form!</h1>
      <h1>Please close this site.</h1>
    </Card>
  );
};
export default CompletedPage;
